<template>
  <div>
    <van-nav-bar
      title="老师评语"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div style="padding: 10px">
      <div v-if="msg">{{ msg }}</div>
      <div v-if="!msg"><van-empty description="老师未评价" /></div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { apiGetTeacherRemark } from "@/service/student.js";

const route = useRoute();

let msg = ref("");
// 获取老师评语
const getRemark = async () => {
  let res = await apiGetTeacherRemark({ caseId: route.query.case_id });
  msg.value = res.data.review_str;
};
getRemark();

const router = useRouter();
const onClickLeft = () => {
  router.go(-1);
};
</script>

<style lang="less" scoped></style>
